
.gift-card-list {
    &__item {
        .thumb-image {
            transition: all 0.3s;
        }
        &:hover,
        &--active {
            .thumb-image {
                box-shadow: var(--shadow-elevation-10) rgba(var(--shadow-color), var(--shadow-opacity));
            }
        }
    }
}
.v-card {
    &::before {
        display: none;
    }
    :deep() {
        .v-card__overlay {
            opacity: 0 !important;
        }
    }
}
