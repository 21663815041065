
// aside
.aside-contents {
    --main-contents-gutter: var(--container-gutter);
    --aside-card-width: 100%;

    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 100%;
    overflow: hidden;
    margin-top: calc(var(--contents-padding-top) * -1);
    margin-bottom: calc(var(--contents-padding-bottom) * -1);
    padding: var(--main-contents-gutter);
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .aside-contents {
        --main-contents-gutter: 20px;
        --aside-card-width: 300px;

        padding: calc(var(--main-contents-gutter) - 10px) var(--main-contents-gutter) calc(var(--main-contents-gutter) - 10px) calc(var(--aside-card-width) + (var(--main-contents-gutter) * 2));
    }
}
@media (min-width: 1600px) {
    .aside-contents {
        --main-contents-gutter: 70px;
        --aside-card-width: 390px;
        padding: calc(var(--main-contents-gutter) - 10px) var(--main-contents-gutter) calc(var(--main-contents-gutter) - 10px) calc(var(--aside-card-width) + var(--main-contents-gutter) + (var(--main-contents-gutter) - 10px));
    }
}
.aside-card {
    height: 200px;
    padding: 12px;
    > .v-card-text {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0;
        border-radius: 0 !important;
        &::-webkit-scrollbar {
            width: 16px;
            border-left: 12px solid transparent;
            background-clip: padding-box;
        }
        &::-webkit-scrollbar-thumb {
            width: 4px;
            border-left: 12px solid transparent;
            background-clip: padding-box;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .aside-card {
        padding: 24px;
        margin-bottom: var(--tit-wrap-padding-bottom);
        > .v-card-text {
            &::-webkit-scrollbar {
                width: 30px;
                border-left: 24px solid transparent;
            }
            &::-webkit-scrollbar-thumb {
                width: 6px;
                border-left: 24px solid transparent;
            }
        }
    }
}
@media (min-width: 1200px) {
    .aside {
        position: absolute;
        // top: var(--main-contents-gutter);
        left: var(--main-contents-gutter);
        z-index: 1;
        width: var(--aside-card-width);
        height: calc(100% - (var(--main-contents-gutter) * 2));
    }
    .aside-card {
        height: 100%;
        margin-bottom: 0;
    }
}
.category-btn {
    height: 60px !important;
    font-size: 1.4rem !important;
    padding: 0 12px !important;
    font-weight: 500;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    text-align: left;
    border: 1px solid rgba(0, 0, 0, 0);
    &:hover {
    }
    .v-btn__content {
        flex: auto;
        white-space: break-spaces;
    }

    .v-avatar {
        width: 40px;
        height: 40px;
        & + span {
            margin-left: 8px;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .category-btn {
        height: 80px !important;
        font-size: 1.6rem !important;
        padding: 0 16px !important;

        .v-avatar {
            width: 48px;
            height: 48px;
            & + span {
                margin-left: 12px;
            }
        }
    }
}
@media (min-width: 1200px) {
}

// scroll-fixed
.scroll-fixed-wrap {
    --tab-height: 50px;
    height: var(--tab-height);
    transition: 0.25s ease-out;
    .card-tabs {
        display: flex;
        height: var(--tab-height);
        .v-tab {
            width: 50% !important;
            max-width: none;
            height: 100% !important;
        }
    }
    .v-tab {
        &--active {
        }
    }
}
.scroll-fixed {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    &.active {
        position: fixed;
        top: calc(var(--header-height) - 1px);
        left: 0;
        width: 100%;
        z-index: 6;
    }
    &:not(.active) {
        .container {
            max-width: 1330px;
            padding: 0;
        }
    }

    ::-webkit-scrollbar {
        width: 12px; /* 스크롤바의 너비 */
    }
    ::-webkit-scrollbar-thumb {
        height: 30%; /* 스크롤바의 길이 */
        background: var(--v-theme-grey-lighten2); /* 스크롤바의 색상 */
        border-radius: 9999px;
        border: 3px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
    }
    ::-webkit-scrollbar-track {
        background: var(--v-theme-grey-lighten4); /*스크롤바 뒷 배경 색상*/
        border-radius: 9999px;
        border: 3px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .scroll-fixed-wrap {
        --tab-height: 50px;
    }
    .scroll-fixed {
        &.active {
            top: calc(var(--header-height) - 1px);
            width: 100%;
            min-height: var(--tab-height);
        }
    }
}
@media (min-width: 1920px) {
    .scroll-fixed {
        &.active {
            width: var(--description-width);
            left: calc(var(--aside-card-width) + var(--main-contents-gutter) + var(--main-contents-gutter) - 10px);
        }
    }
}
@media (max-width: 576px) {
    .scroll-fixed {
        .container {
            padding: 0;
        }
    }
}

// 상품상세
.product-intro {
    &__description {
        width: 100%;
    }
    &__info {
        width: 100%;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .product-intro {
        --info-width: 400px;

        &__info {
            max-width: var(--info-width);
        }
    }
}
@media (min-width: 1200px) {
    .product-intro {
        --info-width: 340px;
    }
}
@media (min-width: 1600px) {
    .product-intro {
        --description-width: 716px;
        --info-width: 460px;

        &__description {
            max-width: var(--description-width);
        }
    }
}
// 상품상세 모바일하단 고정영역
.product-bottom {
    &-fixed,
    &-fixed-view {
        // display: block !important;
        z-index: 6 !important;
    }
    &-fixed {
        box-shadow: none !important;

        &__btn {
            min-width: auto !important;
            width: 100%;
            height: var(--btn-height-xl) !important;
        }
        .v-btn--active > .v-btn__overlay {
            display: none;
        }
    }
    &-fixed-view {
        :deep() {
            .v-bottom-navigation__content {
                flex-direction: column;
                > .v-btn--size-default.v-btn--stacked {
                    width: 100%;
                    min-width: auto !important;
                    max-width: none !important;
                    height: var(--btn-height) !important;
                }
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .product-bottom {
        &-fixed,
        &-fixed-view {
            display: none !important;
        }
    }
}
@media (min-width: 1200px) {
}

.card-tabs {
    height: 70px;
    border: 0;
    margin: 0 -4px !important;
    .v-tab {
        display: block;
        height: 100% !important;
        margin: 0 4px !important;
        padding: 0 !important;
        border-radius: var(--card-border-radius);
        &::before,
        &::after {
            display: none;
        }
        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
        }
        &--selected {
            &:not(.text-primary) {
            }
        }
        :deep() {
            .v-btn__content {
                display: block !important;
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .card-tabs {
        height: 82px;
        .v-tab {
            margin: 0 6px !important;
        }
    }
}
@media (min-width: 1200px) {
}

.product-bottom-fixed {
    &__btn {
        max-width: 100% !important;
    }
}
