
// Direction
.icon--direction {
    &-right,
    &-down {
        transform: rotate(180deg);
    }
    &-left,
    &-right {
        // Size
        &.icon--size {
            &-xx-small {
                width: 10px;
            }
            &-x-small {
                width: 12px;
            }
            &-small {
                width: 14px;
            }
            &-default {
                width: 16px;
            }
            &-large {
                width: 18px;
            }
            &-x-large {
                width: 20px;
            }
            &-xx-large {
                width: 24px;
            }
        }
    }
    &-up,
    &-down {
        // Size
        &.icon--size {
            &-xx-small {
                height: 10px;
            }
            &-x-small {
                height: 12px;
            }
            &-small {
                height: 14px;
            }
            &-default {
                height: 16px;
            }
            &-large {
                height: 18px;
            }
            &-x-large {
                height: 20px;
            }
            &-xx-large {
                height: 24px;
            }
        }
    }
}
