
.gift-card-divider {
    position: relative;
    margin-top: 20px;
}

@media (min-width: 768px) {
    .gift-card-divider {
        margin-top: 0;
    }
}
