
.number-field {
    display: flex;
    background: #fff;
    border: 1px solid var(--border-color);
    border-radius: 8px;

    .v-btn {
        min-width: initial;
    }
    &__input {
        width: 76px;
        text-align: center;
        --input-height-comfortable: var(--btn-height);

        ::v-deep(input) {
            text-align: center;
        }
        ::v-deep(.v-input__control) {
            min-height: var(--btn-height) !important;
        }
    }
}
@media (min-width: 768px) {
}
