
.gift-card-slide {
    .swiper-control {
        top: 50%;
        transform: translate(-50%, -50%);
        .swiper-button-prev,
        .swiper-button-next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            :deep() {
                .v-icon {
                    font-size: var(--btn-icon-font-size-xxl) !important;
                }
            }
        }
        .swiper-button-prev {
            left: 0;
        }
        .swiper-button-next {
            right: 0;
        }
    }
}
:deep() {
    .gift-card-slide {
        ::slotted(.swiper) {
            overflow: visible;
        }
    }
}
.v-card {
    &:hover,
    &--active {
        box-shadow: 0 0 8px 0 rgba(var(--shadow-color), var(--shadow-opacity));
    }
    :deep() {
        .v-card__overlay {
            opacity: 0 !important;
        }
    }
}
