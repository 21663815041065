
::v-deep {
    .sub-visual {
        // background-color: rgba(0, 0, 0, 0.25);
    }
    .sub-tab-wrap {
        background-color: rgba(0, 0, 0, 0.5) !important;
    }
    .v-tabs {
        background-color: transparent !important;
        &:before {
            background-color: rgba(0, 0, 0, 0.25) !important;
        }
    }
    .v-tab {
        color: #fff !important;
        background-color: transparent !important;
        &.v-tab {
            &--active {
                color: var(--v-primary-base) !important;
                background-color: #fff !important;
            }
        }
    }
}
