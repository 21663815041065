
.v-card {
    &:hover,
    &--active {
        box-shadow: var(--shadow-elevation-10) rgba(var(--shadow-color), var(--shadow-opacity));
    }
    :deep() {
        .v-card__overlay {
            opacity: 0 !important;
        }
    }
}
