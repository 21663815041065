
.gift-card-category {
    padding: calc(var(--grid-gutter) * 2) 0;
    margin-bottom: var(--tit-wrap-padding-bottom-lg);
}

.v-btn {
    --btn-hover-opacity: 0 !important;
    &:hover,
    &--active {
        box-shadow: var(--btn-box-shadow) rgba(var(--shadow-color), var(--shadow-opacity));
    }
    :deep() {
        .v-btn__overlay {
            opacity: 0 !important;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .v-btn {
        --btn-border-radius-xxl: 16px;
    }
}
@media (min-width: 1200px) {
}
@media (max-width: 768px) {
    .v-btn {
        --btn-stacked-height-xxl: 76px;
    }
}
@media (max-width: 768px) {
    .v-btn {
        --btn-stacked-height-xxl: 96px;
    }
}
